import React, { useEffect, useRef } from 'react';
import P from 'prop-types';
import './style.scss';

import { isInViewport } from '../../../services/isInViewport';
import Contact_Infos from '../../../components/Contact_Infos';
import Contact_Form from '../../../components/Contact_Form';

function Contact({ id }) {
  const contactBox = useRef();

  useEffect(() => {
    const changeContactOpacity = () => {
      contactBox.current.style.opacity = isInViewport(contactBox.current) ? 1 : 0;
    };

    window.addEventListener('scroll', changeContactOpacity);

    return () => {
      window.removeEventListener('scroll', changeContactOpacity);
    };
  });

  return (
    <section className="contact-wrapper">
      <img
        src={require('../../../media/images/assets/contact_img.png')}
        alt="Drawing with a e-mail letter and a phone"
      />
      <div
        ref={contactBox}
        className="contact_box"
      >
        <div
          className="contact_box-header"
          id={id}
        >
          <h3>ENTRE EM CONTATO</h3>
          <div className="black_line"></div>
        </div>
        <div className="contact_box-contacts">
          <Contact_Infos />
        </div>
        <div className="contact_box-form">
          <Contact_Form />
        </div>
        <div className="contact_box-footer">
          <p>* Obrigado pela visita!</p>
        </div>
      </div>
      <p className="footer">
        <u>
          <a
            href={require('../../../media/docs/codigo_de_etica_e_conduta_politica_anticorrupcao.pdf')}
            target="_blank"
            rel="noreferrer"
          >
            CÓDIGO DE ÉTICA E CONDUTA & POLÍTICA ANTI-CORRUPÇÃO
          </a>
        </u>
      </p>
    </section>
  );
}

Contact.propTypes = {
  id: P.string,
};

export default Contact;

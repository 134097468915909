import React from 'react';
import './style.scss';

function Header_Infos() {
  return (
    <div className="header_infos-wrapper">
      <div className="header_infos-phone">
        <span className="ddd">(19)</span>
        <span className="phone">3242-0232</span>
      </div>
      <div className="header_infos-box">
        <div className="header_infos-email">
          <a
            href="mailto:vigaregua@vigaregua.com.br"
            target="_blank"
            rel="noreferrer"
          >
            vigaregua@vigaregua.com.br
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header_Infos;

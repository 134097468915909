import React from 'react';
import './style.scss';

import Menu_Items from '../../../components/Menu_Items';
import Header_Infos from '../../../components/Header_Infos';

function Header() {
  return (
    <header>
      <div className="header-wrapper container">
        <div className="header_infos">
          <Header_Infos />
        </div>
        <div className="header_menu">
          <Menu_Items />
        </div>
      </div>
    </header>
  );
}

export default Header;

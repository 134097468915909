import React, { useRef } from 'react';
import P from 'prop-types';

import './style.scss';

function Hamburger(props) {
  const bar = useRef([]);
  const { isMenuOpened, setIsMenuOpened } = props;
  const { isMenuTransition, setIsMenuTransition } = props;

  const handleClick = () => {
    isMenuOpened ? setIsMenuOpened(false) : setIsMenuOpened(true);
    isMenuOpened ? (document.body.style.overflow = 'auto') : (document.body.style.overflow = 'hidden');
    isMenuTransition ? setIsMenuTransition(false) : setIsMenuTransition(true);
  };
  return (
    <div className="burger-wrapper">
      <div
        className={`burger`}
        onClick={() => {
          handleClick();
        }}
      >
        <div
          className={`bar ${isMenuOpened ? 'burger-opened' : ''}`}
          ref={(e) => {
            bar.current.push(e);
          }}
        ></div>
        <div
          className={`bar ${isMenuOpened ? 'burger-opened' : ''}`}
          ref={(e) => {
            bar.current.push(e);
          }}
        ></div>
        <div
          className={`bar ${isMenuOpened ? 'burger-opened' : ''}`}
          ref={(e) => {
            bar.current.push(e);
          }}
        ></div>
      </div>
    </div>
  );
}

export default Hamburger;

Hamburger.propTypes = {
  isMenuOpened: P.bool,
  setIsMenuOpened: P.func,
  isMenuTransition: P.bool,
  setIsMenuTransition: P.func,
};

import React from 'react';
import './style.scss';

function Footer() {
  return (
    <footer className="footer-wrapper">
      <span>VIGA RÉGUA ENGENHARAI DE PRÉ-MOLDADOS LTDA.</span>
    </footer>
  );
}

export default Footer;

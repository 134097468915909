import React from 'react';
import Header from './objects/sections/Header';
import Logo from './objects/sections/Logo';
// import Products from './objects/sections/Products';
// import Slogan from './objects/sections/Slogan';
// import Gallery from './objects/sections/Gallery';
import Gap from './objects/sections/Gap';
import Contact from './objects/sections/Contact';
import Footer from './objects/sections/Footer';

function App() {
  return (
    <>
      <Header />
      <main>
        <Logo id="home" />
        {/* <Products id="services" /> */}
        {/* <Slogan /> */}
        {/* <Gallery id="gallery" /> */}
        <Gap />
        <Contact id="contact" />
      </main>
      <Footer />
    </>
  );
}

export default App;

export function isSafariOnDeskton() {
  if (
    navigator.userAgent.includes('Safari') &&
    navigator.userAgent.includes('Macintosh') &&
    !navigator.userAgent.includes('Chrome')
  ) {
    return true;
  } else {
    return false;
  }
}

export function isOnMobile() {
  if (navigator.userAgent.includes('Mobile')) {
    return true;
  } else {
    return false;
  }
}

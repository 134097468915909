import React from 'react';

import './style.scss';

function Contact_Infos() {
  return (
    <article className="contacts_infos">
      <div id="street">
        <address>
          Rod. Francisco Aguirre Proença (SP 101), km 20 <br />
          Jardim Campos Dourados
          <br />
          Monte Mor, São Paulo - Brasil <br />
          Tel: (19) 3242-0232
        </address>
      </div>
      <a
        href="mailto:vigaregua@vigaregua.com.br"
        target="_blank"
        rel="noreferrer"
      >
        vigaregua@vigaregua.com.br
      </a>
      <div id="opening_hours">
        <p>HORÁRIO DE FUNCIONAMENTO:</p>
        <p>Seg - Sex: 8:00 - 17:30</p>
      </div>
    </article>
  );
}

export default Contact_Infos;

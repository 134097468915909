import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Spinner from '../Spinner';

import './style.scss';

function Contacts_Infos() {
  const form = useRef();
  const inputTime = useRef();
  const [emailjsStatus, setEmailjsStatus] = useState({
    status: null,
    response: null,
    message: null,
  });
  const message = {
    success: (
      <>
        <img
          src={require('../../media/images/assets/icons/success.png')}
          alt="success alert"
          width="50px"
        />
        <p>ENVIADO COM SUCESSO !</p>
        <p>Obrigado por entrar em contato conosco através do nosso site. </p>
        <p>Sua mensagem foi enviada para o nosso departamento comercial e entraremos em contato em breve.</p>
      </>
    ),
    error: (
      <>
        <img
          src={require('../../media/images/assets/icons/attention.png')}
          alt="attention alert"
          width="50px"
        />
        <p>ALGO SEU ERRADO !</p>
        <p>Lamentamos informar que ocorreu um erro ao enviar sua mensagem. </p>
        <p>
          Por favor, tente novamente mais tarde ou entre em contato conosco através do nosso telefone, whatsapp, e-mail,
          ou tente novamente mais tarde.
        </p>
        <p>Pedimos desculpas pelo inconveniente causado.</p>
      </>
    ),
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //
    let now = new Date();
    let date = {
      day: `${now.getDate()}`.padStart(2, '0'),
      month: `${now.getMonth() + 1}`.padStart(2, '0'),
      year: `${now.getFullYear()}`.padStart(4, '0'),
      hour: `${now.getHours()}`.padStart(2, '0'),
      minutes: `${now.getMinutes()}`.padStart(2, '0'),
      seconds: `${now.getSeconds()}`.padStart(2, '0'),
    };
    inputTime.current.value = `${date.hour}:${date.minutes}:${date.seconds}h - (${date.day}-${date.month}-${date.year})`;
    //
    setEmailjsStatus((prev) => ({
      ...prev,
      status: 321,
      message: 'Sua mensagem está sendo processada',
    }));
    sendEmail();
    // mockSendEmail(200);
  };

  // eslint-disable-next-line no-unused-vars
  const mockSendEmail = (status) => {
    switch (status) {
      case 200:
        setTimeout(() => {
          setEmailjsStatus((prev) => ({
            ...prev,
            status: 200,
            response: 'response',
            message: message.success,
          }));
        }, 5000);
        form.current.reset();
        break;
      case 500:
        setTimeout(() => {
          setEmailjsStatus((prev) => ({
            ...prev,
            status: 500,
            response: 'error',
            message: message.error,
          }));
        }, 5000);

        break;

      default:
        break;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const sendEmail = () => {
    emailjs.sendForm('baumec-service_k9s1p9o', 'template_fv5qx16', form.current, 'bwzPvfRtCtzGaihD5').then(
      (response) => {
        setEmailjsStatus((prev) => ({
          ...prev,
          status: 200,
          response: response,
          message: message.success,
        }));
        form.current.reset();
      },
      (error) => {
        setEmailjsStatus((prev) => ({
          ...prev,
          status: 500,
          response: error,
          message: message.error,
        }));
      },
    );
  };

  const handleInputFocus = ({ target }) => {
    target.classList.add('focus');
  };

  const handleInputBlur = ({ target }) => {
    target.value === '' ? target.classList.remove('focus') : '';
  };

  return (
    <div
      className="contact_form-wrapper"
      style={emailjsStatus.status !== null ? { height: '250px' } : {}}
    >
      <form
        className="contact_form"
        onSubmit={handleSubmit}
        ref={form}
        style={
          emailjsStatus.status !== null ? { opacity: 0, pointerEvents: `none` } : { opacity: 1, pointerEvents: `all` }
        }
      >
        <div className="field">
          <input
            type="text"
            id="name"
            name="name"
            required
            autoComplete="off"
            onFocus={(e) => {
              handleInputFocus(e);
            }}
            onBlur={(e) => {
              handleInputBlur(e);
            }}
          />
          <label
            htmlFor="name"
            title="Nome:"
            data-title="Nome: ✓"
          ></label>
        </div>
        <div className="field">
          <input
            type="email"
            id="email"
            name="email"
            required
            autoComplete="off"
            onFocus={(e) => {
              handleInputFocus(e);
            }}
            onBlur={(e) => {
              handleInputBlur(e);
            }}
          />
          <label
            htmlFor="email"
            title="E-Mail:"
            data-title="E-Mail: ✓"
          ></label>
        </div>

        <div className="field">
          <input
            type="tel"
            id="phone"
            name="phone"
            required
            autoComplete="off"
            onFocus={(e) => {
              handleInputFocus(e);
            }}
            onBlur={(e) => {
              handleInputBlur(e);
            }}
          />
          <label
            htmlFor="phone"
            title="Telefone:"
            data-title="Telefone: ✓"
          ></label>
        </div>

        <div className="field">
          <input
            type="text"
            id="subject"
            name="subject"
            required
            autoComplete="off"
            onFocus={(e) => {
              handleInputFocus(e);
            }}
            onBlur={(e) => {
              handleInputBlur(e);
            }}
          />
          <label
            htmlFor="subject"
            title="Assunto:"
            data-title="Assunto: ✓"
          ></label>
        </div>

        <div className="field">
          <textarea
            rows={4}
            id="message"
            name="message"
            required
            autoComplete="off"
            onFocus={(e) => {
              handleInputFocus(e);
            }}
            onBlur={(e) => {
              handleInputBlur(e);
            }}
          />
          <label
            htmlFor="message"
            title="Mensagem:"
            data-title="Mensagem: ✓"
            onFocus={(e) => {
              handleInputFocus(e);
            }}
            onBlur={(e) => {
              handleInputBlur(e);
            }}
          ></label>
        </div>
        <button
          type="submit"
          disabled={emailjsStatus.status !== 321 ? false : true}
          value="Send"
        >
          Enviar
        </button>
        <input
          type="hidden"
          ref={inputTime}
          id="time"
          name="time"
        />
        <input
          type="hidden"
          id="company"
          name="company"
          value="VIGAREGUA"
        />
      </form>
      <div
        className="message-wrapper"
        style={emailjsStatus.status !== null ? { opacity: 1 } : { opacity: 0 }}
      >
        <div
          className="spinner-wrapper"
          style={emailjsStatus.status === 321 ? { display: 'block' } : { display: 'none' }}
        >
          <Spinner />
        </div>
        <div className={emailjsStatus.status === 200 ? 'success' : emailjsStatus.status === 500 ? 'error' : 'waiting'}>
          {emailjsStatus.message}
        </div>
      </div>
    </div>
  );
}

export default Contacts_Infos;

import React from 'react';
import P from 'prop-types';

import './style.scss';
function Logo({ id }) {
  return (
    <section
      id={id}
      className="logo-wrapper"
    >
      <div className="logo-image">
        <img
          src={require('../../../media/images/assets/logotipo_vigaregua-01.png')}
          alt="Logo VigaRegua"
          height="100%"
        />
      </div>
    </section>
  );
}

Logo.propTypes = {
  id: P.string,
};

export default Logo;
